import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalConstants} from '../app.module';
import {DataTableDirective} from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})

export class GamesComponent implements OnInit {

  // Declare data storing variables
  data: GameItem[] = [];

  searchedGames: any[] = [];

  constructor(private http: HttpClient) {

    let app: GamesComponent = this;
    app.fillData();
    setInterval(function() {
      app.fillData();
    }, 30000);
  }

  ngOnInit() {
    let app: GamesComponent = this;
    let games = [];
  }


  openDeleteModal(index: number): void {
    let id = this.data[index].id;
    $('#deleteModal').data('myData', id);
    $('#deleteModal').modal('show');
  }

  submitDelete(): void {
    let btn = $('#submitDeleteGame');
    btn.attr('disabled', 'disabled');
    let id = $('#deleteModal').data('myData');
    $('#' + id).remove();

    this.http.get(GlobalConstants.URL  + '/game/remove/' + id).subscribe(data => {
      $('#deleteModal').modal('hide');
      btn.attr('disabled', false);
    }, error => console.error(error));
  }

  searchGames(): void {
    $('#addModalLoadingscreen').attr('style', '');
    this.searchedGames = [];
    this.http.get<any[]>(GlobalConstants.URL + '/game/search/' + $('#gameSearchTxt').val()).subscribe(data => {
        this.searchedGames = data;
        $('#addModalLoadingscreen').attr('style', 'visibility: hidden');
    }, error => console.error(error));
  }

  openAddModal() {
    $('#addModal').modal('show');
  }

  addNewGame(index: number) {
    $('#addModalLoadingscreen').attr('style', '');
    $('.btnAddGame').attr('disabled', 'disabled');
    let id = this.searchedGames[index]._attributes.id;
    let name = this.searchedGames[index].new_name;
    this.http.get<any[]>(GlobalConstants.URL + '/game/add/' + id + '/' + name).subscribe(data => {
      this.searchedGames.splice(index, 1);
      $('#addModalLoadingscreen').attr('style', 'visibility: hidden');
      $('.btnAddGame').removeAttr('disabled');
      this.fillData();
    }, error => console.error(error));
  }

  fillData(): void {
    this.http.get<GameItem[]>(GlobalConstants.URL + '/game/all').subscribe(data => {
      this.data = data;
    }, error => console.error(error));
  }
}

class GameItem {
  id!: number;
  name!: string;
  image!: string;
  thumbnail!: string;
  rating_bgg = 0;
  rating_own = 0;
  min_player!: number;
  max_player!: number;
  min_time_min!: number;
  max_time_min!: number;

  constructor() {
  }
}
