<nav class="navbar navbar-expand navbar-primary navbar-dark">
  <ul class="navbar-nav col-8">
    <li class="nav-item d-sm-inline-block">
      <a routerLink="/votings" class="nav-link">Votings  <i class="fas fa-ticket-alt"></i></a>
    </li>
    <li class="nav-item d-sm-inline-block">
      <a routerLink="/games" class="nav-link">Games  <i class="fas fa-dice"></i></a>
    </li>
  </ul>
  <ul class="navbar-nav" style="alignment: right">
    <li class="nav-item d-sm-inline-block">
      <a href="#" class="nav-link" (click)="Logout()">Logout <i class="fas fa-user"></i></a>
    </li>
  </ul>
</nav>


<div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Login</h4>
      </div>
      <div class="modal-body">
        <div class="input-group mb-3">
          <input id="username" type="email" class="form-control" placeholder="Benutzername" (keydown.enter)="SignIn()">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-user"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input id="passwort" type="password" class="form-control" placeholder="Password" (keydown.enter)="SignIn()">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div class="col-4">
          <button class="btn btn-primary btn-block" (click)="SignIn()">Sign In</button>
        </div>
      </div>
    </div>
  </div>
</div>
