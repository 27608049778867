import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {GlobalConstants} from '../app.module';
declare var $: any;

@Component({
  selector: 'app-votings',
  templateUrl: './votings.component.html',
  styleUrls: ['./votings.component.css']
})
export class VotingsComponent implements OnInit {

  // Declare data storing variables
  voting_id: number = -1;
  data: VotingItem[] = [];
  pool: PoolItem[] = [];
  selectedPool: PoolItem[] = [];
  lastCreatedPool: number = -1;
  voteList: VoteListEntry[] = [];
  voteInfo: VoteInfo[] = [];
  selectGames: SelectGame[] = [];
  hiddenGames: SelectGame[] = [];
  anzahlStimmen: number = 0;
  anzahlBans: number = 0;
  selected_game_count: number = 0;
  user_id: number = Number(localStorage.getItem('userid'));

  constructor(private http: HttpClient) {
    let app: VotingsComponent = this;
    app.fillData();
    setInterval(function() {
      app.fillData();
    }, 30000);
  }

  ngOnInit(): void {

  }

  fillData(): void {
    this.http.get<VotingItem[]>(GlobalConstants.URL + '/vote/all/' + localStorage.getItem('userid')).subscribe(data => {
      this.data = data;
      //console.log(this.data);
      this.user_id = Number(localStorage.getItem('userid'));
    }, error => console.error(error));
  }

  openAddModal(): void {
    $('#addVotingModal').modal('show');
  }

  createVoting(): void {
    $('#addModalLoadingscreen').attr('style', '');
    const playerCount: number = (($('#playerCountInput').val() <= 0) ? 5 : $('#playerCountInput').val()) || 5;
    const votingCount: number = (($('#votingCountInput').val() <= 0) ? 3 : $('#votingCountInput').val()) || 3;
    const banCount: number = (($('#banCountInput').val() < 0) ? 0 : $('#banCountInput').val()) || 0;
    const gameCountSelected: number = (($('#gameCountSelected').val() < 0) ? 2 : $('#gameCountSelected').val()) || 2;
    const gameCountRandom: number = (($('#gameCountRandom').val() < 0) ? 3 : $('#gameCountRandom').val()) || 3;
    const voteType: string = $('#inputType').val() || 'most';
    console.log(GlobalConstants.URL + '/vote/add/' + playerCount + '/' + votingCount + '/' + banCount + '/' + voteType + '/' +
      localStorage.getItem('userid') + '/' + gameCountSelected + '/' + gameCountRandom);
    this.http.get<any>(GlobalConstants.URL + '/vote/add/' + playerCount + '/' + votingCount + '/' + banCount + '/' + voteType + '/' +
      localStorage.getItem('userid') + '/' + gameCountSelected + '/' + gameCountRandom)
      .subscribe(data => {
      $('#addVotingModal').modal('toggle');
      $('#addModalLoadingscreen').attr('style', 'visibility: hidden');
      this.fillData();
    }, error => console.error(error));
  }

  openSelectGamesModal(index:number) {
    let voting_id = this.data[index].id;
    this.http.get<SelectGame[]>(GlobalConstants.URL + '/get/selectable/games/' + voting_id).subscribe(selectGameList => {
      this.selectGames = selectGameList;
      this.hiddenGames = selectGameList;
      this.selected_game_count = selectGameList[0].games_amount;
      this.voting_id = voting_id;
      $('#addGamesModal').modal('show');
    }, error => console.error(error));
  }

  openVoting(index: number): void {
      let voting_id = this.data[index].id;
      this.http.get<VoteListEntry[]>(GlobalConstants.URL + '/get/my/votes/' + voting_id + '/' + localStorage.getItem('userid')).subscribe(voteList => {
          this.voteList = voteList;
          this.anzahlStimmen = voteList[0].votenumber;
          this.anzahlBans = voteList[0].bannumber;
          $('#VoteModal').modal('show');
      }, error => console.error(error));
  }

  loadingGamePool(index: number): void {
    this.voting_id = this.data[index].id;
    this.pool = [];
    this.selectedPool = [];
    this.http.get<PoolItem[]>(GlobalConstants.URL + '/generate/pool/' + this.voting_id).subscribe(data => {
      this.pool = data;
      while (data[0].visible === 1) {
        this.selectedPool.push(this.pool.shift() as PoolItem);
      }
      $('#addModal').modal('show');
    }, error => console.error(error));
  }


  createPool(): void {
    $('#submitCreateVotingPool').attr('disabled', 'disabled');
    const json = {id: this.voting_id, pool: this.selectedPool};
    this.http.post(GlobalConstants.URL + '/vote/add/pool/' + this.voting_id, json).subscribe(data => {
      $('#addModal').modal('hide');
      $('#submitCreateVotingPool').removeAttr('disabled');
      this.fillData();
    }, error => console.error(error));
  }

  resetPoolItem(id: number): void {
    this.pool.push(this.selectedPool[id] as PoolItem);
    this.selectedPool[id] = this.pool.shift() as PoolItem;
  }

  addVote(index: number): void {
    if (this.anzahlStimmen > 0 && this.voteList[index].normal_votes === 0) {
      this.voteList[index].normal_votes = this.voteList[index].normal_votes + 1;
      this.anzahlStimmen = this.anzahlStimmen - 1;
    } else if (this.voteList[index].normal_votes < 0) {
      this.voteList[index].normal_votes = this.voteList[index].normal_votes + 1;
      this.anzahlBans = this.anzahlBans + 1;
    }
  }

  removeVote(index: number): void {
    if (this.voteList[index].normal_votes === 0 && this.anzahlBans > 0) {
      this.anzahlBans = this.anzahlBans - 1;
      this.voteList[index].normal_votes = this.voteList[index].normal_votes - 1;
    } else if (this.voteList[index].normal_votes > 0) {
      this.voteList[index].normal_votes = this.voteList[index].normal_votes - 1;
      this.anzahlStimmen = this.anzahlStimmen + 1;
    }
  }

  sendVotes(): void {
    $('#submitSetVotes').attr('disabled', 'disabled');
    $('#submitSetVotes').addClass('disabled');
    const voting_id = this.voteList[0].voting_id;
    const user_id = localStorage.getItem('userid');
    const json = {voting_id: voting_id, user_id: user_id, votes: this.voteList};
    this.http.post(GlobalConstants.URL + '/send/my/votes', json).subscribe(data => {
      this.fillData();
      $('#VoteModal').modal('hide');
      $('#submitSetVotes').removeAttr('disabled');
      $('#submitSetVotes').removeClass('disabled');
    }, error => console.error(error));
  }

  sendSelectedGames(): void {
    $('#addSelectedGamesModalLoadingscreen').attr('style', '');
    $('#submitSetSelectedGames').attr('disabled', 'disabled');
    $('#submitSetSelectedGames').addClass('disabled');
    const user_id = localStorage.getItem('userid');
    const json = {voting_id: this.voting_id, user_id: user_id, selectedGames: this.hiddenGames};
    this.http.post(GlobalConstants.URL + '/send/my/selectedGames', json).subscribe(data => {
      $('#addSelectedGamesModalLoadingscreen').attr('style', 'visibility: hidden');
      $('#addGamesModal').modal('hide');
      this.fillData();
      $('#submitSetSelectedGames').removeAttr('disabled');
      $('#submitSetSelectedGames').removeClass('disabled');
    }, error => console.error(error));
  }

  openDeleteModal(index: number): void {
    let id = this.data[index].id;
    $('#deleteModal').data('myData', id);
    $('#deleteModal').modal('show');
  }

  openArchiveModal(index: number): void {
    let id = this.data[index].id;
    $('#archiveModal').data('myData', id);
    $('#archiveModal').modal('show');
  }

  submitDelete(): void {
    $('#removeVotingModalLoadingscreen').attr('style', '');
    let btn = $('#submitDeleteVoting');
    btn.attr('disabled', 'disabled');
    let id = $('#deleteModal').data('myData');
    $('#' + id).remove();

    this.http.get(GlobalConstants.URL  + '/voting/remove/' + id).subscribe(data => {
      $('#removeVotingModalLoadingscreen').attr('style', 'visibility: hidden');
      $('#deleteModal').modal('hide');
      btn.attr('disabled', false);
    }, error => console.error(error));
  }

  submitArchive(): void {
    $('#archiveVotingModalLoadingscreen').attr('style', '');
    let btn = $('#submitArchiveVoting');
    btn.attr('disabled', 'disabled');
    let id = $('#archiveModal').data('myData');
    $('#' + id).remove();

    this.http.get(GlobalConstants.URL  + '/voting/archive/' + id).subscribe(data => {
      $('#archiveVotingModalLoadingscreen').attr('style', 'visibility: hidden');
      $('#archiveModal').modal('hide');
      btn.attr('disabled', false);
    }, error => console.error(error));
  }

  openEndModal(index: number): void {
    let id = this.data[index].id;
    $('#endVotingModal').data('myData', id);
    $('#endVotingModal').modal('show');
  }

  submitEndVoting(): void {
    $('#endVotingModalLoadingscreen').attr('style', '');
    let btn = $('#submitEndVoting');
    btn.attr('disabled', 'disabled');
    let id = $('#endVotingModal').data('myData');

    this.http.get(GlobalConstants.URL  + '/voting/end/' + id).subscribe(data => {
      this.fillData();
      $('#endVotingModalLoadingscreen').attr('style', 'visibility: hidden');
      $('#endVotingModal').modal('hide');
      btn.attr('disabled', false);
    }, error => console.error(error));
  }

  openVoteInfoModal(index: number): void {
    let id = this.data[index].id;
    this.http.get<VoteInfo[]>(GlobalConstants.URL  + '/voting/info/' + id).subscribe(data => {
      this.voteInfo = data;
      $('#VoteInfoModal').modal('show');
    }, error => console.error(error));
  }

  uncheckSelectableGame(index: number) {
    this.selectGames[index].is_selected = 0;
    this.selected_game_count = this.selected_game_count + 1;
  }

  checkSelectableGame(index: number) {
    if (this.selected_game_count > 0) {
      this.selectGames[index].is_selected = 1;
      this.selected_game_count = this.selected_game_count - 1;
    }
  }

  filterGames(searchText: string) {
    this.selectGames =  this.hiddenGames.filter(function(el: SelectGame) {
      return el.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }
}

class VotingItem {
  id!: number;
  winner_id: number = 0;
  bannumber = 0;
  votenumber = 0;
  count_max_voter = 0;
  count_voted_voter = 0;
  selected_game_count = 0;
  random_game_count = 0;
  type = '';
  thumbnail = '';
  name = '';
  anzahl = 0;
  status = 0;
  ersteller_id = -1;
  current_votes = 0;

  constructor() {
  }
}

class PoolItem {
    id!: number;
    name: string = '';
    image: string = '';
    thumbnail: string = '';
    rating_bgg = 0.0;
    rating_own = 0.0;
    min_player = 0;
    max_player = 0;
    min_time_min = 0;
    max_time_min = 0;
    row_num = 0;
    visible = 0;

    constructor() {
    }
}

class VoteListEntry {
  game_id: number = -1;
  thumbnail: string = '';
  game_name: string = '';
  bannumber = 0;
  votenumber = 0;
  voting_id = 0;
  user_id = 0;
  normal_votes = 0;
  ban_votes = 0;

  constructor() {
  }
}

class VoteInfo {
  name: string = '';
  game_id: number = -1;
  thumbnail: string = '';
  stimmen = 0;
  bans = 0;

  constructor() {
  }
}

class SelectGame {
  name: string = '';
  game_id: number = -1;
  thumbnail: string = '';
  is_selected = 0;
  games_amount = 0;

  constructor() {
  }
}
