import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {VotingsComponent} from './votings/votings.component';
import {GamesComponent} from './games/games.component';

const routes: Routes = [
  {path: 'games', component: GamesComponent},
  {path: '', component: VotingsComponent},
  {path: 'votings', component: VotingsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [GamesComponent, VotingsComponent];
