<div class="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
  <table class="table table-bordered table-striped dataTable" role="grid" id="datatable" style="width: auto">
    <thead>
    <tr style="padding: 0px">
      <th style="margin: 0px; padding: 6px">
        <div class="row">
          <div class="col-4" style="margin-left: 0px">Games</div>
          <!--<div class="input-group input-group-sm">
            <input id="searchfield" class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
            <div class="input-group-append">
              <i class="fas fa-search"></i>
            </div>
          </div>-->
          <div class="col-3" style="margin: auto">
          </div>
          <div class="col-2" style="margin: auto">
            <button type="button" class="btn btn-outline-primary" (click)="fillData()">
              <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-2" style="margin: auto">
            <button type="button" class="btn btn-outline-success" (click)="openAddModal()">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let group of data; let z = index" id="{{group.id}}">
      <td style="padding: 0px">
        <div class="row" style="margin: 0px">
          <div class="col-4" style="margin-left: 0px"><img src="{{group.thumbnail}}" width="100" height="100"></div>
          <div class="col-5" style="margin: auto">
            <div class="row" style="font-weight: bolder">{{group.name}}</div>
            <div class="row" style="font-weight: lighter">Spieler: {{group.min_player}} - {{group.max_player}}</div>
            <div class="row" style="font-weight: lighter">Dauer: {{group.min_time_min}} - {{group.max_time_min}}</div>
          </div>
          <!--<div class="col-2" style="margin: auto">
            <div class="row" style="font-weight: lighter">BGG: {{group.rating_bgg}}</div>
            <div class="row" style="font-weight: lighter">GJ: {{group.rating_own}}</div>
          </div>-->
          <div class="col-2" style="margin: auto">
            <button type="button" class="btnDelete btn btn-outline-danger" (click)="openDeleteModal(z)">
              <i class="fas fa-minus-circle"></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Boardgame löschen?</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Möchtest du das Boardgame wirklich löschen?</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button id="submitDeleteGame" type="button" class="btn btn-danger btnSubmitDelete" (click)="submitDelete()">Löschen</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div id="addModalLoadingscreen" class="overlay d-flex justify-content-center align-items-center" style="visibility: hidden">
      <i class="fas fa-2x fa-sync fa-spin"></i>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Suchen</h4>
        <div class="input-group mb-3">
          <input id="gameSearchTxt" type="text" class="form-control rounded-0" style="margin-left: 10px">
          <span class="input-group-append">
            <button type="button" id="searchGames" class="btn btn-outline-info btn-flat" (click)="searchGames()"><i class="fas fa-search"></i></button>
          </span>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <table id="addGameTable" class="table table-striped table-valign-middle">
          <thead>
          <tr>
            <th>Games</th>
          </tr>
          </thead>
          <tbody id="addGameTableBody">
            <tr *ngFor="let sGames of searchedGames; let x = index" id="{{sGames._attributes.objectid}}">
              <td style="padding: 0px">
                <div class="row" style="margin: 0px">
                  <div class="col-4" style="margin-left: 0px">
                    <div *ngIf="(sGames.thumbnail!==undefined) else NoImage">
                      <img src="{{sGames.thumbnail._text}}" width="100" height="100">
                    </div>
                    <ng-template #NoImage>
                      <img src="../../assets/noimage.png" width="100" height="100">
                    </ng-template>
                  </div>
                  <div class="col-5" style="margin: auto">
                    <div class="row" style="font-weight: bolder">{{sGames.new_name}}</div>
                  </div>
                  <!--<div class="col-2" style="margin: auto">
                    <div class="row" style="font-weight: lighter">BGG: {{group.rating_bgg}}</div>
                    <div class="row" style="font-weight: lighter">GJ: {{group.rating_own}}</div>
                  </div>-->
                  <div class="col-2" style="margin: auto">
                    <button type="button" class="btn btn-outline-success btnAddGame" (click)="addNewGame(x)">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>
