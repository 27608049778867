import { Component, OnInit } from '@angular/core';
import { User } from '../shared/user';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../app.module';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public user: User, private http: HttpClient) {}

  ngOnInit(): void {
    this.isLogged();
  }

  Logout() {
    this.user.id = "-1";
    this.user.username = "";
    localStorage.setItem("userid", "-1");
    localStorage.setItem("username", "");
    $('#username').val("");
    $('#passwort').val("");
    this.isLogged();
  }

  isLogged(): void {
    if (this.user.id == "-1" || localStorage.getItem("userid") == "-1") {
      $('#LoginModal').modal({backdrop: 'static', keyboard: false});
      $('#LoginModal').modal('show');
    }
  }

  SignIn() {

    $.post( GlobalConstants.URL+"/user/login",{ username: $('#username').val(), passwort: $('#passwort').val() }, function( data: any ) {
      if (data.id == "-1") {
        alert("Anmeldung fehlgeschlagen");
      } else {
        localStorage.setItem("userid", data.id);
        localStorage.setItem("username", data.username);
        //user.id = data.id;
        //user.username =  data.username;

        $("#LoginModal").modal('toggle');
        $('#username').val("");
        $('#passwort').val("");
      }
    });
  }

}
