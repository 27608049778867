<div class="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
  <table class="dataTable" role="grid" id="datatable" style="width: 99%">
    <thead>
    <tr style="padding: 0px">
      <th style="margin: 0px; padding: 6px">
        <div class="row">
          <div class="col-4" style="margin-left: 0px">Votings</div>
          <!--<div class="input-group input-group-sm">
            <input id="searchfield" class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
            <div class="input-group-append">
              <i class="fas fa-search"></i>
            </div>
          </div>-->
          <div class="col-3" style="margin: auto">
          </div>
          <div class="col-2" style="margin: auto">
            <button type="button" class="btn btn-outline-primary" (click)="fillData()">
              <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="col-2" style="margin: auto">
            <button type="button" class="btn btn-outline-success" (click)="openAddModal()">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let group of data; let a = index" id="{{group.id}}">
      <td style="padding-bottom: 0px; padding-top: 0px">
      <div [ngSwitch]="group.status">
        <div *ngSwitchCase="'0'">
          <div class="info-box bg-warning">
            <div class="info-box-content col-8 inner">
                <span class="info-box-number">Spiele wählen</span>
                <span class="info-box-text">Abgestimmt: ({{group.count_voted_voter}}/{{group.count_max_voter}})</span>
                <span class="info-box-text">Anzahl Stimmen: {{group.selected_game_count}}</span>
                <span class="info-box-text">Typ:{{group.type}}</span>
              <span *ngIf="group.ersteller_id == this.user_id" style="margin-top: 5px" class="info-box-text">
                  <button style="margin-right: 10px" type="button" class="btn btn-danger btn-sm" (click)="openDeleteModal(a)">Löschen</button>
              </span>
            </div>
            <div class="col-4" (click)="openSelectGamesModal(a)">
              <i style="width: 100px; height: 100px" class="fas fa-plus-square"></i>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'1'">
          <div class="info-box bg-info">
            <div class="info-box-content col-8 inner">
              <span class="info-box-number">Spiele gewählt</span>
              <span class="info-box-text">Abgestimmt: ({{group.count_voted_voter}}/{{group.count_max_voter}})</span>
              <span class="info-box-text">Stimmen: {{group.votenumber}} & Ban: {{group.bannumber}}</span>
              <span class="info-box-text">Typ:{{group.type}}</span>
              <span *ngIf="group.ersteller_id == this.user_id" style="margin-top: 5px" class="info-box-text">
                  <button style="margin-right: 10px" type="button" class="btn btn-danger btn-sm" (click)="openDeleteModal(a)">Löschen</button>
              </span>
            </div>
            <div class="col-4">
              <i style="width: 100px; height: 100px" class="fas fa-check-square"></i>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'2'">
          <div class="info-box bg-warning">
            <div class="info-box-content col-8 inner">
              <span class="info-box-number">Random Spiele wählen</span>
              <span class="info-box-text">Anzahl Spiele: {{group.random_game_count}}</span>
              <span class="info-box-text">Stimmen: {{group.votenumber}} & Ban: {{group.bannumber}}</span>
              <span class="info-box-text">Typ:{{group.type}}</span>
              <span *ngIf="group.ersteller_id == this.user_id" style="margin-top: 5px" class="info-box-text">
                  <button style="margin-right: 10px" type="button" class="btn btn-danger btn-sm" (click)="openDeleteModal(a)">Löschen</button>
              </span>
            </div>
            <div class="col-4" (click)="loadingGamePool(a)">
              <i style="width: 100px; height: 100px" class="fas fa-dice"></i>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'3'">
          <div class="info-box bg-info">
            <div class="info-box-content col-8 inner">
              <span class="info-box-number">Random Spiele gewählt</span>
              <span class="info-box-text">Abgestimmt: ({{group.count_voted_voter}}/{{group.count_max_voter}})</span>
              <span class="info-box-text">Stimmen: {{group.votenumber}} & Ban: {{group.bannumber}}</span>
              <span class="info-box-text">Typ:{{group.type}}</span>
              <span *ngIf="group.ersteller_id == this.user_id" style="margin-top: 5px" class="info-box-text">
                  <button style="margin-right: 10px" type="button" class="btn btn-danger btn-sm" (click)="openDeleteModal(a)">Löschen</button>
              </span>
            </div>
            <div class="col-4">
              <i style="width: 100px; height: 100px" class="fas fa-check-square"></i>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'4'">
          <div class="info-box bg-warning">
            <div class="info-box-content col-8 inner">
              <span class="info-box-number">Spiele Voten</span>
              <span class="info-box-text">Abgestimmt: ({{group.count_voted_voter}}/{{group.count_max_voter}})</span>
              <span class="info-box-text">Stimmen: {{group.votenumber}} & Ban: {{group.bannumber}}</span>
              <span class="info-box-text">Typ:{{group.type}}</span>
              <span *ngIf="group.ersteller_id == this.user_id" style="margin-top: 5px" class="info-box-text">
                  <button style="margin-right: 10px" type="button" class="btn btn-danger btn-sm" (click)="openDeleteModal(a)">Löschen</button>
              </span>
            </div>
            <div class="col-4" (click)="openVoting(a)">
              <i style="width: 100px; height: 100px" class="fas fa-clipboard-list"></i>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'5'">
          <div class="info-box bg-info">
            <div class="info-box-content col-8 inner">
              <span class="info-box-number">Spiele Gevotet</span>
              <span class="info-box-text">Abgestimmt: ({{group.count_voted_voter}}/{{group.count_max_voter}})</span>
              <span class="info-box-text">Stimmen: {{group.votenumber}} & Ban: {{group.bannumber}}</span>
              <span class="info-box-text">Typ:{{group.type}}</span>
              <span *ngIf="group.ersteller_id == this.user_id" style="margin-top: 5px" class="info-box-text">
                  <button style="margin-right: 10px" type="button" class="btn btn-danger btn-sm" (click)="openDeleteModal(a)">Löschen</button>
              </span>
            </div>
            <div class="col-4">
              <i style="width: 100px; height: 100px" class="fas fa-clipboard-check"></i>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'6'">
          <div class="info-box bg-success">
            <div class="info-box-content col-8 inner">
              <span class="info-box-number">{{group.name}}</span>
              <span class="info-box-text">Abgestimmt: ({{group.count_voted_voter}}/{{group.count_max_voter}})</span>
              <span class="info-box-text">Stimmen: {{group.votenumber}} & Ban: {{group.bannumber}}</span>
              <span class="info-box-text">Typ:{{group.type}}</span>
              <div class="row">
                <span style="margin-top: 5px" class="info-box-text">
                    <button style="margin-right: 10px" type="button" class="btn btn-primary btn-sm" (click)="openArchiveModal(a)">Archivieren</button>
                </span>
                <span *ngIf="this.user_id == 1" style="margin-top: 5px" class="info-box-text">
                    <button style="margin-right: 10px" type="button" class="btn btn-danger btn-sm" (click)="openDeleteModal(a)">Löschen</button>
                </span>
              </div>
            </div>
            <div class="col-4" (click)="openVoteInfoModal(a)">
              <img src="{{group.thumbnail}}" width="100" height="100">
            </div>
          </div>
        </div>
      </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>


<div class="modal fade" id="addVotingModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div id="addModalLoadingscreen" class="overlay d-flex justify-content-center align-items-center" style="visibility: hidden">
      <i class="fas fa-2x fa-sync fa-spin"></i>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Voting erstellen</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <p class="col-7">Anzahl Spieler</p>
          <input id="playerCountInput" class="col-4" type="number" value="5"/>
        </div>
        <div class="row">
          <p class="col-7">Anzahl Stimmen</p>
          <input id="votingCountInput" class="col-4" type="number" value="3"/>
        </div>
        <div class="row">
          <p class="col-7">Anzahl Downvotes</p>
          <input id="banCountInput" class="col-4" type="number" value="0"/>
        </div>
        <div class="row">
          <p class="col-7">Anzahl Spieleauswahl</p>
          <input id="gameCountSelected" class="col-4" type="number" value="3"/>
        </div>
        <div class="row">
          <p class="col-7">Anzahl zufällige Spiele</p>
          <input id="gameCountRandom" class="col-4" type="number" value="10"/>
        </div>
        <div class="row">
          <p class="col-4">Typ</p>
          <select id="inputType" class="form-control custom-select col-7">
            <option selected value="most">meisten Stimmen</option>
            <option value="random">Zufall Stimmenverteilt</option>
          </select>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
        <button type="button" class="btn btn-success" (click)="createVoting()">Erstellen</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="addGamesModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog">
    <div id="addSelectedGamesModalLoadingscreen" class="overlay d-flex justify-content-center align-items-center" style="visibility: hidden">
      <i class="fas fa-2x fa-sync fa-spin"></i>
    </div>
    <div class="modal-content">
      <div class="modal-header row">
        <div class="col-5">
          <h4 class="modal-title">Anzahl ({{selected_game_count}})</h4>
        </div>
        <div class="col-7">
          <div class="input-group mb-3">
            <input id="searchGames" type="text" class="form-control" (input)="filterGames($any($event.target).value)">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <table id="addGamesTable" class="table table-striped table-valign-middle">
          <tbody id="addGamesTableBody">
          <tr *ngFor="let selectGameItem of selectGames; let i = index" id="select_{{selectGameItem.game_id}}">
            <td [ngStyle]="{'background-color': (selectGameItem.is_selected == 0) ? 'white' : '#3dceff'}">
              <span *ngIf="selectGameItem.is_selected == 0 else isChecked">
                <div class="row">
                  <div class="col-4" style="padding: 1px"><img src="{{selectGameItem.thumbnail}}" width="100" height="100"></div>
                  <div class="col-4">{{selectGameItem.name}}</div>
                  <div class="col-4">
                    <button type="button" class="btn btn-outline-success" (click)="checkSelectableGame(i)">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </span>
              <ng-template #isChecked>
                <div class="row">
                  <div class="col-4" style="padding: 1px"><img src="{{selectGameItem.thumbnail}}" width="100" height="100"></div>
                  <div class="col-4" style="font-weight: bold">{{selectGameItem.name}}</div>
                  <div class="col-4">
                    <button type="button" class="btn btn-outline-danger" (click)="uncheckSelectableGame(i)">
                      <i class="fas fa-minus-circle"></i>
                    </button>
                  </div>
                </div>
              </ng-template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer justify-content-between">
        <button id="submitSetSelectedGames" type="button" class="btn btn-success" (click)="sendSelectedGames()">Bestätigen</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Auswahlpool wählen</h4>
      </div>
      <div class="modal-body">
        <table id="addGameTable" class="table table-striped table-valign-middle">
          <thead>
          <tr>
            <th>Bild</th>
            <th>Name</th>
            <th></th>
          </tr>
          </thead>
          <tbody id="addGameTableBody">
            <tr *ngFor="let poolItem of selectedPool; let i = index" id="{{poolItem.id}}">
              <td>
                <div class="col-3" style="padding: 1px"><img src="{{poolItem.thumbnail}}" width="100" height="100"></div>
              </td>
              <td>
                {{poolItem.name}}
              </td>
              <td>
                <button type="button" class="btn btn-outline-primary" (click)="resetPoolItem(i)">
                  <i class="fas fa-random"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
        <button id="submitCreateVotingPool" type="button" class="btn btn-success" (click)="createPool()">Bestätigen</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="VoteModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Voten (Stimmen:{{anzahlStimmen}} / Downvotes:{{anzahlBans}})</h4>
      </div>
      <div class="modal-body">
        <table id="VoteTable" class="table table-striped table-valign-middle">
          <thead>
          <tr>
            <th>Games</th>
          </tr>
          </thead>
          <tbody id="VoteTableBody">
            <tr *ngFor="let voteItem of voteList; let i = index" id="{{voteItem.game_id}}">
              <td [ngStyle]="{'background-color': ((voteItem.normal_votes < 0) ? '#ff9191' : ((voteItem.normal_votes > 0) ? '#3dceff' : 'white'))}">
                <div class="row">
                  <div class="col-4" style="padding: 1px"><img src="{{voteItem.thumbnail}}" width="100" height="100"></div>
                  <div class="col-4">{{voteItem.game_name}}</div>
                  <div class="col-4">
                    <div class="row">
                      <label for="{{voteItem.game_id}}VoteNumber">Voten</label>
                    </div>
                    <div class="row">
                      <div class="input-group input-group-sm">
                        <span class="input-group-prepend">
                          <button type="button" class="btn btn-outline-dark btn-flat addVoteMinus" (click)="removeVote(i)"><i class="fas fa-minus"></i></button>
                        </span>
                        <input id="{{voteItem.game_id}}VoteNumber" type="text" class="form-control" readonly value="{{voteItem.normal_votes}}" style="text-align: center">
                        <span class="input-group-append">
                          <button type="button" class="btn btn-outline-dark btn-flat addVotePlus" (click)="addVote(i)"><i class="fas fa-plus"></i></button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer justify-content-between">
        <button id="submitSetVotes" type="button" class="btn btn-success" (click)="sendVotes()">Bestätigen</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div id="removeVotingModalLoadingscreen" class="overlay d-flex justify-content-center align-items-center" style="visibility: hidden">
      <i class="fas fa-2x fa-sync fa-spin"></i>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Voting löschen?</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Möchtest du das Voting wirklich löschen?</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button id="submitDeleteVoting" type="button" class="btn btn-danger btnSubmitDelete" (click)="submitDelete()">Löschen</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="archiveModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div id="archiveVotingModalLoadingscreen" class="overlay d-flex justify-content-center align-items-center" style="visibility: hidden">
      <i class="fas fa-2x fa-sync fa-spin"></i>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Voting archivieren?</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Möchtest du das Voting wirklich archivieren?</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button id="submitArchiveVoting" type="button" class="btn btn-primary" (click)="submitArchive()">Archivieren</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="endVotingModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div id="endVotingModalLoadingscreen" class="overlay d-flex justify-content-center align-items-center" style="visibility: hidden">
      <i class="fas fa-2x fa-sync fa-spin"></i>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Voting Abschließen?</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Möchtest du das Voting wirklich Abschließen?</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button id="submitEndVoting" type="button" class="btn btn-primary btnSubmitDelete" (click)="submitEndVoting()">Abschließen</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="VoteInfoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog-scrollable modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Voting Info</h4>
      </div>
      <div class="modal-body">
        <table id="VoteInfoTable" class="table table-striped table-valign-middle">
          <thead>
          </thead>
          <tbody id="VoteInfoTableBody">
          <tr *ngFor="let voteItem of voteInfo; let i = index" id="info{{voteItem.game_id}}">
            <td>
              <div class="row">
                <div class="col-4" style="padding: 1px"><img src="{{voteItem.thumbnail}}" width="100" height="100"></div>
                <div class="col-8">
                  <div style="margin-left: 5%">
                    <div class="row" style="font-weight: bolder">{{voteItem.name}}</div>
                    <div class="row" style="font-weight: bold">Gesamt: {{voteItem.stimmen + voteItem.bans}}</div>
                    <div class="row">Stimmen: {{voteItem.stimmen}}</div>
                    <div class="row">Downvotes: {{voteItem.bans}}</div>
                  </div>
                </div>
              </div>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" data-dismiss="modal">Abbruch</button>
      </div>
    </div>
  </div>
</div>
