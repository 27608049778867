import { Injectable } from '@angular/core';

@Injectable()
export class User {
    public id: string = localStorage.getItem("userid") || "-1";
    public username: string = localStorage.getItem("username") || "";

    /**
     var token = "xxx";
     localStorage.setItem("token", token);
     localStorage.getItem("token"); //returns "xxx"
     */

}
